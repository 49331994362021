<template>
  <div class="navbar-container main-menu-content">
    <CongressData
      v-if="showCongressMenu"
      horizontal
    />
    <horizontal-nav-menu-items :items="navMenuItems" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal/index'
import congressNavItems from '@/navigation/horizontal/congress'
import CongressData from '@core/layouts/components/CongressData.vue'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
    CongressData,
  },
  computed: {
    showCongressMenu() {
      return this.$route.meta && this.$route.meta.congressView && this.$route.params.id
    },
    navMenuItems() {
      if (this.showCongressMenu) {
        return congressNavItems(this.$route.params.id)
      }

      return navMenuItems
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
